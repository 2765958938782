export const blogMixin = {
    methods: {

        dateformat(value) {
            // console.log(value.replace(/-/g, "/"));
            const d = new Date(value.replace(/-/g, "/"));
            // console.log(d);
            let month = d.getMonth() + 1; 
            if (month < 10) {
                month = "0" + month;
            }
            return d.getDate() + "." + month + "." + d.getFullYear();
        },
        postlink(postslug) {
            return '/'+postslug + ".html";
        },
    }
  }